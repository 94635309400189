
import { reactive } from 'vue'
import routes from '@/router/routes'
import { ElMessageBox, ElMessage } from 'element-plus'

export default {
	setup(props, context) {
		return reactive({
			PageLoadFinish: false,
			userRoutes: [],
			asideWidth: '200px',
			TopActiveIndex: 'index',	//顶部导航选中Index
			LeftActiveIndex: '',		//左侧导航选中Index
		})
	},
	mounted() {
		this.routes = routes
		if(this._user.roleId) {
			this.webAuths = this._user.webAuths
			console.log(6566)
			for(let topName in this.routes) {
				this.cleaningMenu(this.routes[topName], '.'+topName)
			}
		}
		this.userRoutes = this.routes
		let indexs = this.initRoute()
		this.TopActiveIndex = indexs[0]
		this.LeftActiveIndex = indexs[1]
		this.PageLoadFinish = true
		console.log(this.userRoutes)
		// console.log(this._user)
		this.$forceUpdate()
	},
	methods: {
		
		//清洗菜单
		cleaningMenu: function(route, key, childIndex){
			//如果存在子级
			if(route.children) {
				this.cleaningMenu(route.children, key + '.children')
			}
			//数组菜单类型
			else {
				//倒序循环
				for(let arIndex=route.length-1; arIndex>-1; arIndex--) {
					let item = route[arIndex]
					//如果存在子级
					if(item.children) {
						this.cleaningMenu(item.children, key + '[' + arIndex + '].children', arIndex)
					}
					//单个路由
					else {
						//路由为系统 或 隐藏的跳出
						if(item.System || item.hide) continue
						//用户无权访问的路由
						if(this.webAuths.indexOf(item.name)==-1) {
							//删除当前路由
							eval('this.routes' + key + '.splice(' + arIndex + ', 1)')
							//验证路由分组是否被清空
							if(eval('this.routes' + key).length == 0) {
								//清空分组
								if(childIndex !== undefined){
									eval( 'this.routes' + (key+'#').replace('['+childIndex+'].children#', '') + '.splice(' + childIndex + ', 1)' )
									// console.log('Delete : ', parentRoute, 'this.routes' + (key+'#').replace('.children['+childIndex+']#', '') )
								}
							}
						}
					}
				}
			}
		},
		
		//切换顶部菜单
		changeHeaderMenu: function(e){
			this.TopActiveIndex = e.index
		},
		
		//跳转
		forwrad: function(item){
			this.LeftActiveIndex = item.name
			//页面跳转
			if(item.path) {
				this.$router.push({ path: item.path, query: item.query})
			}
			//调用路由中设置的函数
			else if(item.call) {
				item.call(this, item)
			}
			//调用本界面的函数
			else if(item.self) {
				this[item.self](item)
			}
		},
		
		//退出登录
		logout: function(){
			ElMessageBox.confirm(
				'是否退出当前账户111?',
				'信息窗',
				{
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				}
			)
			.then(() => {
				this._post('/System/User/loginOut', {}, (res)=>{
					this._user.userToken = null
					this._user.accessToken = null
					localStorage.removeItem(this._app.name +'_'+ this._app.appId)
					window.location.href = window.location.protocol + '//' + window.location.host + '/'+Math.random()+'#/system/login'
				})
				// this.$router.push({ path: '/system/login'})
			})
			.catch(() => {})
		},
		
		//初始化
		initRoute: function(){
			//获取当前路由
			let path = this.$route.path
			if(!path || (path == '/') || (path == '/system/welcome')){
				return ['index', 'SystemWelcome']
			}else {
				for(var index1 in this.routes){
					var route1 = this.routes[index1]	//一级路由
					if(route1.children)
					for(var index2 in route1.children) {
						var route2 = route1.children[index2]
						if(route2.children)
						for(var index3 in route2.children) {
							var route3 = route2.children[index3]
							if(route3.children)
							for(var index4 in route3.children) {
								let route4 = route3.children[index4]
								if(route4.path==path) return [index1, route4.name]
							}
							else if(route3.path==path) return [index1, route3.name]
						}
						else if(route2.path==path) return [index1, route2.name]
					}
				}
			}
		},
	},
}
